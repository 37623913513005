body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #fefefd;
  color: #020200;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #020200;
  color: #fefefd;
}

.navbar ul {
  list-style: none;
  display: flex;
  gap: 1rem;
}

.navbar a {
  color: #fefefd;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar a:hover {
  color: #93a27b;
}

.hero-section {
  text-align: center;
  padding: 6rem 2rem;
  background-size: cover;
  background-position: center;
  color: #fefefd;
}

.hero-content {
  background: rgba(0, 0, 0, 0.6);
  padding: 2rem;
  display: inline-block;
  border-radius: 8px;
}

.hero-content button.learn-more-btn {
  padding: 0.75rem 1.5rem;
  border: none;
  background-color: #93a27b;
  color: #fefefd;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;
}

.hero-content button.learn-more-btn:hover {
  background-color: #a2a2a0;
}

.new-launches-section,
.top-compounds-section,
.top-areas-section {
  padding: 4rem 2rem;
  background-color: #fefefd;
}

h2 {
  font-size: 2rem;
  color: #020200;
  margin-bottom: 2rem;
}

.launches-grid,
.compounds-grid,
.areas-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.launch-item,
.compound-item,
.area-item {
  background-color: #fefefd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.launch-item:hover,
.compound-item:hover,
.area-item:hover {
  transform: translateY(-10px);
}

.launch-item img,
.compound-item img,
.area-item img {
  width: 100%;
  height: auto;
}

.new-launches-section .launch-item,
.top-compounds-section .compound-item,
.top-areas-section .area-item {
  padding: 1rem;
  text-align: center;
}

.new-launches-section .launch-item h3,
.top-compounds-section .compound-item h3,
.top-areas-section .area-item h3 {
  margin-top: 1rem;
  font-size: 1.25rem;
  color: #020200;
}

.new-launches-section .launch-item p,
.top-compounds-section .compound-item p,
.top-areas-section .area-item p {
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #a2a2a0;
}

.quick-links-section {
  padding: 4rem 2rem;
  display: flex;
  justify-content: space-around;
  background-color: #fefefd;
}

.quick-link {
  padding: 2rem;
  background-color: #ecc5c0;
  color: #020200;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;
  width: 45%;
}

.quick-link:hover {
  transform: translateY(-10px);
  background-color: #93a27b;
  color: #fefefd;
}

.quick-link h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.quick-link p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.quick-link a {
  color: #020200;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.quick-link a:hover {
  color: #fefefd;
}

.about-section {
  padding: 4rem 2rem;
  background-color: #93a27b;
  color: #fefefd;
  text-align: center;
}

.contact-section {
  padding: 4rem 2rem;
  background-color: #fefefd;
  color: #020200;
  text-align: center;
}

.contact-section form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 400px;
  margin: 0 auto;
}

.input-field {
  padding: 0.75rem;
  border: 1px solid #a2a2a0;
  border-radius: 4px;
  background-color: #a2a2a0;
  color: #020200;
}

.send-btn {
  padding: 0.75rem 1.5rem;
  border: none;
  background-color: #ecc5c0;
  color: #020200;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;
}

.send-btn:hover {
  background-color: #a2a2a0;
}

footer {
  padding: 2rem;
  background-color: #020200;
  color: #fefefd;
  text-align: center;
}

.social-media a {
  margin: 0 0.5rem;
  font-size: 1.5rem;
  color: #fefefd;
  transition: color 0.3s ease;
}

.social-media a:hover {
  color: #ecc5c0;
}
